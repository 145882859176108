import "./styles.css";

import SliderCaptcha from "@slider-captcha/react";

function verifiedCallback(token) {
  console.log("Captcha token: " + token);
}

export default function App() {
  return (
    <div
      style={{
        color: "blue",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 300
      }}
    >
      <SliderCaptcha
        create="https://cat-captcha.herokuapp.com/captcha/create"
        verify="https://cat-captcha.herokuapp.com/captcha/verify"
        callback={verifiedCallback}
      />
    </div>
  );
}
